<template>
  <main class="work-details">
    <b-container fluid>
      <b-row>
        <!-- <b-col lg="6" class="mb-2">
          <VideoMedia :confPhotos="confVideos" />
        </b-col> -->
        <b-col lg="12">
          <PhotoMedia :confPhotos="medies" />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="work-details__details">
      <b-row>
        <b-col sm="12">
          <section class="work-details__heading-container">
            <b-img style="display:block" :src="work.logo && work.logo.path">
            </b-img>
            <h2 class="main-heading">{{ work.name }}</h2>
          </section>
        </b-col>
        <b-col sm="12" lg="9">
          <p class="work-details__text" style="word-break: break-word;">
            {{ work.description }}
          </p>
        </b-col>
        <b-col sm="12" lg="3">
          <h2 class="sub-heading">{{ $t("heading.available_platforms") }}</h2>
          <Platforms :platforms="work.platforms" />
          <h2 class="sub-heading mt-5">{{ $t("heading.date") }}</h2>
          <h3 class="work-details__date">24/9/2019</h3>
        </b-col>

        <advantages-list :list-data="listData" />
      </b-row>
    </b-container>

    <Project />
  </main>
</template>

<script>
import { ShowData } from "@/helpers/apiMethods";

export default {
  name: "WorkDetails",
    metaInfo() {
    return {
      title: this.work.name,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.work.name
        },
        {
          vmid: "description",
          name: "description",
          content: this.work.description
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${this.work.name} متجر سولوشن بلص`
        }
      ]
    };
  },
  components: {
    Project: () => import("@/components/Project"),
    PhotoMedia: () => import("./components/photoMedia"),
    // VideoMedia: () => import("./components/videoMedia"),
    AdvantagesList: () => import("@/components/AdvantagesList"),
    Platforms: () => import("@/components/Platforms")
  },

  data() {
    return {
      work: {
        features: []
      },

      medies: []
    };
  },

  methods: {
    async handleShowWork() {
      try {
        const { slug } = this.$route.params;
        const res = await ShowData({ reqName: "works", id: slug });
        const { work } = res.data;
        this.work = work;
        this.medies = work.media.filter((el) => el.title !== "logo");
      } catch (err) {
        console.log(err);
      }
    }
  },
  computed: {
    listData() {
      if (this.work) {
        let data = { title: "advantages" };
        data.items = this.work.features;

        return data;
      } else {
        return [];
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.handleShowWork();
      },
      immediate: true
    }
  }
};
</script>
